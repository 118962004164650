import { Toaster as Sonner, toast as sonnerToast } from 'sonner'

import { cn } from '#utils/utils.js'
import { Button } from './Button'
import { Icon } from './Icon'
import { Spinner } from './Spinner'

type ToastProps = {
  type: 'success' | 'warning' | 'info' | 'error' | 'loading'
  title: string
  description?: string
  button?: {
    label: string
  }
}

const Toast = (props: ToastProps & { id: string | number }) => {
  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-md min-w-72 backdrop-blur-lg px-4 py-2 shadow-lg',
        {
          'bg-success-200/90 text-success-800': props.type === 'success',
          'bg-destructive-200/90 text-destructive-800': props.type === 'error',
          'bg-warning-200/90 text-warning-800': props.type === 'warning',
          'bg-accent-200/90 text-accent-800': props.type === 'info',
          'bg-primary text-background': props.type === 'loading'
        }
      )}
    >
      {props.type === 'loading' ? (
        <Spinner size="sm" />
      ) : (
        <Icon
          name={
            props.type === 'success'
              ? 'confetti'
              : props.type === 'info'
                ? 'circle-info'
                : 'warning'
          }
          className="size-4.5"
        />
      )}

      <div className="flex items-center gap-4">
        <div className="flex flex-1 items-center">
          <div className="w-full">
            <p className="text-sm font-medium">{props.title}</p>
            <p className="empty:hidden">{props.description}</p>
          </div>
        </div>

        {props.button ? (
          <Button
            variant="outline"
            size="xs"
            onClick={() => {
              sonnerToast.dismiss(props.id)
            }}
          >
            {props.button.label}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const Toaster = () => {
  return (
    <Sonner
      className="toaster group flex flex-col items-center"
      position="bottom-center"
    />
  )
}

const toast = (toast: Omit<ToastProps, 'id'>, duration = 5000) => {
  return sonnerToast.custom((id) => <Toast id={id} {...toast} />, {
    duration
  })
}

export { type ToastProps as Toast, toast, Toaster }
